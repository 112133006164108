import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../image/Image"

import { obtenirImage, obtenirImages } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueDetailCaisseAmericaine"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "boutiqueDibond"
            "boutiqueDetailDibond"
            "boutiqueCaisseAmericaine"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function ExplicationsFinitions({ page }) {
  const resultatsRequete = useStaticQuery(requete)
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysageTresPetit",
    "imagesPortraitPetit"
  )

  return (
    <article>
      <section
        id="section-finitions"
        className="section-conteneur"
        style={{ marginTop: "50px" }}
      >
        <h2 className="section-titre">Les finitions</h2>
        <h3>Le tirage seul</h3>
        <p>
          Prix d’appel des tirages proposés à la vente, vous recevrez le tirage
          roulé.{" "}
          <strong>
            Il sera nécessaire de le faire encadrer ou le contre-coller
          </strong>{" "}
          sur un support rigide. Après votre achat, vous recevrez un certificat
          d’authenticité numéroté et signé par l’auteur par courrier
          électronique.
        </p>
        <h3>Contre-collage sur aluminium-Dibond</h3>
        <p>
          L’impression est réalisée sur{" "}
          <strong>un papier Fine Art baryté d’exposition</strong> à la texture
          incomparable et aux noirs très profonds. Le tirage est{" "}
          <strong>contre-collé</strong> sur une plaque d’aluminium-Dibond de 2 à
          3 mm d’épaisseur pour garantir sa rigidité et permettre son
          accrochage. Les fixations sont fournies.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "boutiqueDibond")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "boutiqueDetailDibond")}
            langue={page.langue}
          />
        </div>
        <p>Après votre achat, vous recevrez par pli postal :</p>
        <ul>
          <li>Un hologramme d’identification à coller au dos du tirage.</li>
          <li>Un certificat d’authenticité signé de la main de l’auteur.</li>
        </ul>
        <h3>Encadrement en caisse américaine</h3>
        <p>
          L’impression est réalisée sur un papier{" "}
          <strong>Fine Art baryté d’exposition</strong> à la texture
          incomparable et aux noirs très profonds. Le tirage est contre-collé
          sur une plaque d’aluminium placée dans un encadrement en caisse
          américaine <strong>réalisée sur mesure</strong>. Il n’y a pas de
          passe-partout ni de verre, le tirage est mis en valeur et semble
          flotter dans son encadrement. Il s’agit de la finition la plus
          prestigieuse proposée dans cette boutique.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "boutiqueCaisseAmericaine")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "boutiqueDetailCaisseAmericaine")}
            langue={page.langue}
          />
        </div>
        <p>Après votre achat, vous recevrez par pli postal :</p>
        <ul>
          <li>Un hologramme d’identification à coller au dos du tirage.</li>
          <li>Un certificat d’authenticité signé de la main de l’auteur.</li>
        </ul>
      </section>
      <section className="section-conteneur" style={{ marginTop: "50px" }}>
        <h2 className="section-titre">Les papiers et les encres</h2>
        <div id="papiers-et-encres">
          <h3>Les encres</h3>
          Tous les tirages sont réalisés avec des encres pigmentaires de très
          haute qualité par un laboratoire français reconnu. Utilisées avec
          d’excellents papiers, ces encres ont une tenue dans le temps{" "}
          <strong>pouvant s’étendre sur plusieurs générations.</strong>
          <h3>Les papiers</h3>
          <ul>
            <li>
              <strong>Les tirages seuls</strong> sont imprimés sur du papier
              haute densité 310 grammes. Son aspect satiné combine l’éclat du
              papier brillant tout en étant résistant aux reflets, une qualité
              propre au papier mat.
            </li>
            <li>
              <strong>Les tirages d’art contre-collés</strong> sur
              aluminium-Dibond ou encadrés en caisse américaine sont imprimés
              sur du papier Fine Art baryté. Ce papier haut de gamme est utilisé
              pour les expositions des plus grands photographes et permet le
              rendu de noirs très profonds. Il possède en outre un grain donnant
              un véritable relief aux astrophotographies.
            </li>
          </ul>
        </div>
      </section>
    </article>
  )
}

ExplicationsFinitions.propTypes = {
  page: PropTypes.object,
  ref: PropTypes.object,
}
